export const FirebaseConfig = {
	"projectId": "delapps2023",
	"appId": "1:595022388505:web:8129522e68d75d0740b988",
	"databaseURL": "https://delapps2023-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "delapps2023.appspot.com",
	"locationId": "asia-southeast2",
	"apiKey": "AIzaSyD4omaT_w36aT6WYtxFGwPxp6o0HwWuaf8",
	"authDomain": "delapps2023.firebaseapp.com",
	"messagingSenderId": "595022388505",
	"measurementId": "G-TMEE0H1P5N"
};